<template>
  <div class="user-page" style="height: calc(100vh - 50px)">
    <div class="user-info df-al-c bg-white" v-if="this.$loginState()">
      <div class="head">
        <img :src="userInfo.headUrl" alt="" />
      </div>
      <div class="info">
        <div class="user-name van-ellipsis">{{ userInfo.name }}</div>
        <div class="phone">{{ userInfo.phone | phoneFilter }}</div>
      </div>
    </div>
    <!-- <div class="user-info df-al-c bg-white" v-if="!this.$loginState() && this.$isWXMiniProgram()"> -->
    <div class="user-info df-al-c bg-white" v-if="!this.$loginState()">
      <div class="head">
        <img src="https://tiebapic.ningmengyun.com/default/default-headportrait.jpg" alt="" />
      </div>
      <div>
        <button class="login-btn green" @click="toMiniProgramLogin()">立即登录</button>
      </div>
    </div>
    <div class="user-option">
      <div class="option-list bg-white">
        <div class="item df-al-c van-hairline--bottom" @click="toCompany">
          <div class="df-al-c">
            <img src="@img/company.png" alt="" />
            <div>我的公司</div>
          </div>
          <img class="arrow" src="@img/right.png" alt="" />
        </div>
        <div class="item df-al-c" @click="toFeedback">
          <div class="df-al-c">
            <img src="@img/feedback.png" alt="" />
            <div>意见反馈</div>
          </div>
          <img class="arrow" src="@img/right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="bottom-btn" v-if="this.$loginState()">
      <van-button @click="logout()">退出</van-button>
    </div>
    <LayoutFooter />
  </div>
</template>

<script>
import LayoutFooter from "@components/LayoutFooter.vue";
import { getUserInfo, getAccountSets } from "../../http/api";
export default {
  name: "user",

  components: {
    LayoutFooter,
  },

  data() {
    return {
      userInfo: {
        headUrl: null,
        name: "",
        phone: "",
      },
    };
  },

  filters: {
    phoneFilter(val) {
      return val ? val.replace(val.substring(3, 7), "****") : null;
    },
  },

  mounted() {
    getUserInfo().then((res) => {
      if (res.ExtraData && res.ExtraData.HeadImg) {
        this.userInfo.headUrl =
          "data:image/jpeg;base64," + res.ExtraData.HeadImg;
      }
      this.userInfo.phone = res.Mobile;
      this.userInfo.name = res.NickName;
    });
  },

  methods: {
    toCompany() {
      if (!this.$loginState() && this.$isWXMiniProgram()) {
        this.$wechatMiniProgramToLogin()
        return;
      }

      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      getAccountSets().then((res) => {
        if (!res.statusCode) {
          this.$toast.clear();
          this.$toast(res.message);
          return;
        }
        if (res.data.length == 0) {
          this.$toast.clear();
          this.$dialog
            .confirm({
              className: "tip-model",
              message: "您还未绑定财务软件",
              theme: "round-button",
              confirmButtonText: "去绑定",
              confirmButtonColor: "#33C759",
              cancelButtonColor: "#ffffff",
            })
            .then(() => {
              let url = "/pages/share/index";
              this.$wechatMiniProgramNavigateTo({ url });
            })
            .catch(() => {
              // on cancel
            });
        } else {
          this.$toast.clear();
          this.$router.push({
            name: "company",
          });
        }
      });
    },

    toFeedback() {

      if (!this.$loginState() && this.$isWXMiniProgram()) {
        this.$wechatMiniProgramToLogin()
        return
      }

      this.$router.push({
        name: "feedback",
      });
    },

    logout() {
      localStorage.removeItem("token");
      setTimeout(() => {
        let isWxMini = window.__wxjs_environment === "miniprogram";
        if (isWxMini) {
          window.wx.miniProgram.navigateTo({
            url: `/pages/logout/index`,
          });
        } else {
          this.$router.replace({ name: "home" });
        }
      }, 200);
    },

    toMiniProgramLogin() {
      if (!this.$loginState() && this.$isWXMiniProgram()) {
        this.$wechatMiniProgramToLogin()
      }
    }

  },
};
</script>

<style lang="less" scoped>
.user-page {
  padding-top: 16px;
  box-sizing: border-box;
}

.user-info {
  padding: 18px 24px;
  line-height: 24px;

  .head {
    width: 68px;
    height: 68px;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    img[src=""],
    img:not([src]) {
      opacity: 0;
    }
  }

  .user-name {
    width: 200px;
    font-size: 20px;
  }

  .phone {
    text-align: left;
    color: #999999;
    margin-top: 4px;
  }
}

.user-option {
  margin-top: 16px;

  .option-list {
    padding: 0 8px;

    .item {
      padding: 13px 8px;
      line-height: 22px;
      justify-content: space-between;

      img {
        width: 22px;
        height: 22px;
        margin-right: 12px;
      }

      img.arrow {
        width: 16px;
        height: 16px;
        margin: 0;
      }
    }
  }
}

.bottom-btn {
  bottom: 90px;

  button {
    margin: 0;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    font-size: 16px;
  }
}

.login-btn {
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  &.green {
    color: #01c257;
  }
}
</style>
